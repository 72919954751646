.modal_delete_content {
  display: flex;
  flex-direction: column;
  min-height: 32vh;
}
.modal_delete_content p {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 1.6rem;
}
.modal_delete_content .modal_group_buttons {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 1.6rem;
  width: 100%;
  padding: 0 6rem;
}
.modal_group_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
  padding: 0 6rem;
  margin-top: 6rem;
}

.order_details span {
  font: 700;
  font-size: 1.4rem;
  color: rgb(199, 199, 88);
  padding: 0px 4px 0px 0px;
  margin: 0px;
}
.order_details p {
  font: 500;
  font-size: 1.3rem;
  padding: 0px;
  margin: 0px;
}
