.seat-management {
    padding: 20px;
    width: 100%;
  }
  
  .seat-management table {
    width: 100%;
    border-collapse: collapse;
  }
  
   .seat-management td {
    padding: 5px;
    /* text-align: center; */
    position: relative;
  }
  .seat-management th:not(:first-child){
    padding: 5px;
  }
  
  .square-button {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }
  
  /* Premium : F5CBA7 
  Recliner : F2D7D5
  Standard : FDFEFE
  Wheelchair : E74C3C
  Kids: E8F8F5
  Booked: 839192
  Reserved : E5E7E9 */
  
  .square-button.recliner {
    border: 1px solid #e90bcb;
  }
  .square-button.premium {
    border: 1px solid #efcd0d;
  }
  .square-button.standard {
    border: 1px solid #e37f08;
  }
  
  .square-button.unavailable {
    border: 1px solid #d00519;
  }
  
  .square-button.stair {
    border: 1px solid #007bff;
  }
  
  .square-button.kids {
    border: 1px solid #9dfcfc;
  }
  
  .square-button.wheelchair {
    border: 1px solid #ff8c00;
  }
  
  .square-button:hover {
    background-color: #ddd;
  }

  .square-button.booked{
    border: 1px solid gray;
  }

  .tool-tip-text {
    display: none;
  }
  
  .seat-act-area:hover .tool-tip-text {
    display: block;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 30px;
    left: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    z-index: 1000;
  }
  
.dropdown-menu.col {
    left: -20px;
  } 
   
  .dropdown-button {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .dropdown-button:hover {
    background-color: #f1f1f1;
  }
  
  .header-label {
    font-size: 12px;
  }
  