.hall_modal {
    z-index: 1060;
}

.hall_modal,
.hall_modal_backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.hall_modal_backdrop {
    background-color: rgb(112 112 112 / 82%);
}

.hall_modal_dialog {
    position      : relative;
    max-width     : 80vw;
    margin        : 1.75rem auto;
    pointer-events: none;
}

.hall_modal.small .hall_modal_dialog {
    max-width: 48.6rem;
}
.hall_modal.medium .hall_modal_dialog {
    max-width: 97.2rem;
}

.hall_modal_dialog_scrollable {
    height: calc(100% - 3.5rem);
}

.hall_modal_dialog_centered {
    display    : flex;
    align-items: center;
    min-height : calc(100% - 3.5rem);
}

.hall_modal_content {
    position        : relative;
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    background-color: var(--gray-8); 
    border-radius   : 1rem;
    pointer-events  : auto;
    padding         : 3rem 4rem; 
    z-index         : 2;
}

.hall_modal_dialog_scrollable .hall_modal_content {
    max-height: 100%;
    overflow  : hidden;
}

.hall_modal_dialog_scrollable .hall_modal_body::-webkit-scrollbar {
    display: none;
} 

.hall_modal_dialog_scrollable .hall_modal_body {
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
} 

