
/* Tabs.css */

.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative;
  }
  
  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    position: relative;
  }
  
  .tab-no {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    background-color: #ccc;
    color: white;
  }
  
  .tab-title {
    font-size: 14px;
    text-align: center;
    color: #666;
  }
  
  .active-tab .tab-no {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .active-tab .tab-title {
    color: var(--primary-color);
  }
  
  .tab.active-tab ~ .tab::after {
    background-color: #ccc;
  }
  
  .tab.active-tab::after {
    background-color: var(--primary-color);
  }
  
  