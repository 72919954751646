.small_sidebar {
  width: 7.5vw; 
  height: 90vh;
  margin-top: -1px;
  background-color: var(--gray-8);
  border-right: 1.5px solid var(--primary-color);
  overflow-x: hidden;
  overflow-y: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none;  */
}
/* .small_sidebar::-webkit-scrollbar {
  display: none;
} */


::-webkit-scrollbar {
  width: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primary-color); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); 
}

.small_sidebar .container {
    width: 100%;
}
