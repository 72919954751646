.naming_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.namingModal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-height: 80vh;
  max-width: 40vw;
  overflow-y: auto;
}

.naming-button {
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.naming-button.close {
  background: #007bff;
}

.naming-button.save {
  background-color: black;
  color: white;
}

.naming_input_field {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: black;
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
}

