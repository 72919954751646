.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    margin-top: 20px;
    border-radius: 1rem;
    padding: 0.5rem;
    background: var(--gray-8);
}

.pagination-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0.5em 1em;
    margin: 0 0.2em;
    font-size: 1em;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}

.pagination-button.active {
    background-color: var(--primary-color);
    color: white;
}

.pagination-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
