/* fonts import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* root variables */
:root {
  --primary-color: #f9bd34;
}

/* body, html and root imports */
*,
body,
html {
  font-family: "Inter", sans-serif;
}

body {
  font-size       : 1.6rem;
  background-color: var(--black);
  color           : var(--gray-3);
}

a {
  color: var(--primary-color);
}

.grid_container {
  display: grid;
}

.flex_container {
  display: flex;
}

/* --------------------------------------------------------------- */

main {
  height : 90vh;
  width  : 100vw;
  display: flex;
}

.screen-container {
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 92.5vw;
  height         : 90vh;
  padding: 0 2rem 0 4rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* btn group */
.btn_group {
  display: grid;
  gap: 2.4rem;
  justify-content: flex-end;
}
.btn_group.two {
  grid-template-columns: auto auto;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.open {
  opacity: 1; 
}

.filter_select {
  padding: 10px 30px 10px 10px;
  border-radius: 10px;
  /* min-width: 150px; */
  background: rgba(0, 0, 0, 0.3);
  color: var(--gray-3);
  font-weight: 700;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.filter_select option{
  color: white;
  background: var(--gray-3);
  font-size: 16px;
}


.fixed_btn{
  position: fixed;
}

.order_header {
  display: flex;
    gap: 20px;
    align-items: center;
}

.seat_csv{
  border: 1px solid white;
  width: 50%;
  margin: 0 auto;
  width: min-content;
  white-space: nowrap;
  padding: 5px 10px;
  border-radius: 5px;
}

.download{
  border-radius: 5px;
    border: 1px solid var(--gray-3);
    font-family: Plus Jakarta Sans;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1.2rem 4.6rem;
}

.download:hover{
  background: var(--black);
  color: white;
  
}

.soldOut {
  background-color: blue;
  white-space: nowrap;
  border: 1px solid white;
  padding: 1rem 1.5rem;
  color: white;
  border-radius: 5px;
}

.sellingFast {
  background-color: green;
  white-space: nowrap;
  border: 1px solid white;
  padding: 1rem 1.5rem;
  color: white;
  border-radius: 5px;
}

.sellingSlow {
  background-color: yellow;
  white-space: nowrap;
  border: 1px solid white;
  padding: 1rem 1.5rem;
  color: var(--gray-4);
  border-radius: 5px;
}

.archive_btn {
  padding: 10px;
  background: #e9db33;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
