

   .namingModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .namingModal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-height: 80vh;
    max-width: 40vw;
    overflow-y: auto;
  }
  
  .letter-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-height: 60vh;
    overflow-y: auto;
  }
  
  .letter-button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
    background-color: rgb(223, 223, 223);
    border: 1px solid black;
    border-radius: 5px;

  }
  
  .naming_close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  