.dashboard_crud_holder {
  width: 100%;
  height: 100%;
  padding: 4rem 0px 2rem;
  overflow-y: auto;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.event_details {
  display: grid;
  width: 100%;
  padding-top: 40px;
  gap: 20px;
}


.event_name_image {
  display: flex;
  align-items: center;
  gap: 5px;
}



.event_image {
  width: 45px;
  height: 45px;
  border-radius: 8px;
}


.event_name {
  font-size: 14px;
}


.card_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  background: var(--gray-7);
  margin-bottom: 1rem;
  padding: 2rem 1rem;
  border-radius: 1rem;
}

.event_btn_container {
  display: flex;
  gap: 5px;
}

.event_btn_container button {
  color: var(--primary-color);
  font-size: 12px;
  padding: 10px 15px;
  border-radius: 5px;
  background: #735b16c7;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.heading {
  text-align: center;
  font-size: 20px;
  color: white;
}

.content_wrapper {
  margin: 20px auto;
  height: 70dvh;
  width: 80%;
  border: 1px solid var(--gray-6);
  border-radius: 10px;
  padding: 3rem;
  background: var(--gray-9);
}

.see_btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.see_btn_container button {
  background: var(--primary-color);
  font-size: 16px;
  text-align: center;
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: 600;
}

.order_details_container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
}

.details_card_container {
  background: var(--gray-7);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 20px;
}

.details_card_container:not(:nth-child(5)) {
  margin-bottom: 1rem;
}

.card_icon {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: #735b16c7;
  justify-content: center;
  padding:15px;
  border-radius: 8px;
  font-size: 30px;
}

.total {
  padding-top: 5px;
  font-size: 32px;
  font-weight: 800;
  color: white;
}